import React, { Component } from 'react';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import Home from '../../components/Home/Home';
import DevelopmentTools from '../../components/DevelopmentTools/DevelopmentTools';
import Project from '../../components/Project/Project';
import Footer from '../../components/Footer/Footer';

class Layout extends Component {
    render () {
      return (
          <div>
            <Toolbar/>
            <Home/>
            <DevelopmentTools/>  
            <Project/>
            <Footer/>                             
          </div>
      );
    }
}



export default Layout;