import classes from './SideDrawer.module.scss';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import BackDrop from '../BackDrop/BackDrop';
import NavItem from '../Navigation/NavItem/NavItem';
import {BsBoxArrowInRight} from 'react-icons/bs';

const SideDrawer = (props) => {
    let attachedClasses = [classes.SideDrawer, classes.Close].join(' ');

    if (props.open === true) {
        attachedClasses = [classes.SideDrawer, classes.Open].join(' ');
    }
    return (
        <Aux>
            <BackDrop show={props.open} clicked={props.onClickBackDrop}/>
            <div className={attachedClasses}>
                <ul onClick={props.onClickBackDrop}>
                    <NavItem href='#home'>Home</NavItem>
                    <NavItem href='#skills'>Skills</NavItem>
                    <NavItem href='#projects'>Projects</NavItem>
                    <NavItem href='#contact'>Contact</NavItem>
                    <li className={classes.CloseSideDrawerBtn}><BsBoxArrowInRight size={40}/></li>     
                </ul>
            </div>
        </Aux>
    )
}

export default SideDrawer;