import classes from './DevelopmentTools.module.scss';
import Aux from '../../hoc/Auxiliary/Auxiliary';

const developmentTools = () => (
    <Aux>
        <div id='skills' className={classes.MainContainer}>
            <div className={classes.Title}>
                <h1>Development Tools</h1>
            </div>            
            <div className={classes.ContainerSlider}>        
                <div className={classes.Slider}>
                    <i className="devicon-html5-plain-wordmark colored" title="HTML"></i>
                    <i className="devicon-css3-plain-wordmark colored" title="CSS"></i>
                    <i className="devicon-javascript-plain colored" title="Javascript"></i>
                    <i className="devicon-react-plain-wordmark colored" title="Reactjs"></i>
                    <i className="devicon-php-plain colored" title="php"></i>
                    <i className="devicon-laravel-plain-wordmark colored" title="Laravel"></i>
                    <i className="devicon-mysql-plain-wordmark colored" title="MySQL"></i>
                    <i className="devicon-nodejs-plain colored" title="NodeJs"></i>
                    <i className="devicon-mongodb-plain-wordmark colored" title="Mongodb"></i>
                    <i className="devicon-express-original colored" title="express"></i>
                    <i className="devicon-git-plain-wordmark colored" title="git"></i>
                    <i className="devicon-gitlab-plain-wordmark colored" title="gitlab"></i>
                    <i className="devicon-vuejs-plain-wordmark colored" title="VueJs"></i>
                    {/* repeat set of icons to reflect the illusion */}
                    <i className="devicon-html5-plain-wordmark colored" title="HTML"></i>
                    <i className="devicon-css3-plain-wordmark colored" title="CSS"></i>
                    <i className="devicon-javascript-plain colored" title="Javascript"></i>
                    <i className="devicon-react-plain-wordmark colored" title="Reactjs"></i>
                    <i className="devicon-php-plain colored" title="php"></i>
                    <i className="devicon-laravel-plain-wordmark colored" title="Laravel"></i>
                    <i className="devicon-mysql-plain-wordmark colored" title="MySQL"></i>
                    <i className="devicon-nodejs-plain colored" title="NodeJs"></i>
                    <i className="devicon-mongodb-plain-wordmark colored" title="Mongodb"></i>
                    <i className="devicon-express-original colored" title="express"></i>
                    <i className="devicon-git-plain-wordmark colored" title="git"></i>
                    <i className="devicon-gitlab-plain-wordmark colored" title="gitlab"></i>
                    <i className="devicon-vuejs-plain-wordmark colored" title="VueJs"></i>	
                </div>       
            </div>
        </div>        
    </Aux>
    
)



export default developmentTools;