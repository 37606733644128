import classes from './NavItem.module.scss';
import Aux from '../../../hoc/Auxiliary/Auxiliary';


const navItem = (props) => (
    <Aux>
        <a className={classes.NavItem} href={props.href}>{props.children}</a>
    </Aux>
);

export default navItem;