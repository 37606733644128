import classes from './Footer.module.scss';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import {BsPhone} from 'react-icons/bs';
import {FiMail} from 'react-icons/fi';
import {AiFillLinkedin, AiFillFacebook} from 'react-icons/ai';


const Footer = () => (
    <Aux>
        <div id='contact' className={classes.FooterContainer}>
            <div className={classes.BackgroundLayer}></div>
            <div className={classes.Content}>
                <h1>Contacts</h1>
                <div className={classes.Contacts}>
                    <p><BsPhone size={20}/> +639351621630</p>
                    <p><FiMail size={20}/> jaspherdugang@gmail.com</p>
                    <a href='https://www.linkedin.com/in/jaspher-dugang-83935917a/' target="_blank" rel="noreferrer"><AiFillLinkedin size={50}/></a>
                    <a href='https://www.facebook.com/JaspheW/' target="_blank" rel="noreferrer"><AiFillFacebook size={50}/></a>
                </div>                
            </div>                        
        </div>
        <div className={classes.Circa}>
            <h3>Disclaimer</h3>
            <p>No copyright infringement is intended. This is only for educational purposes and not for profit. Some asset/s used are not owned by the developer unless otherwise stated.</p>
            <p>Some project aren't maintained well and might be outdated.</p>
            <p>© 2021 | Jaspher Dugang</p>
        </div>
    </Aux>
)


export default Footer;