import React, { useState } from 'react';
import classes from './Project.module.scss';
import LOL from '../../assets/images/lol2.png';
import intime from '../../assets/images/intime1.png';
import turtleRace from '../../assets/images/turtlerace2.png';
import hypher from '../../assets/images/hypher.png';
import soundStudio from '../../assets/images/soundstudio.png';
import ProjectCard from './ProjectCard';
import Aux from '../../hoc/Auxiliary/Auxiliary';


const Project = () => {
    const [projects] = useState([
        {
            image : soundStudio,
            title : 'SoundMNLStudio',
            description : 'This is an online booking for band rehearsal studio with a calendar without using pre-built library. The user must register and login to be able to book a schedule.',
            tools : ['ReactJs','SCSS', 'Javascript', 'MySQL', 'NodeJs', 'Express'],
            link : 'http://soundmnlstudio.codejaspher.com/'            
        },
        {
            image : LOL,
            title : 'League of Legends Page',
            description : 'A static website for Leauge of Legends, a MOBA/RPG type of game.',
            tools : ['HTML','CSS'],
            link : 'https://jaspher.gitlab.io/leagueoflegends'            
        },    
        {
            image : hypher,
            title : 'HypherHoops',
            description : 'An online booking system for basketball court. Calendar schedule is shown at home page which is also intercative for booking a specific schedule',
            tools : ['Laravel', 'HTML','CSS', 'Javascript', 'jQuery', 'MonggoDB', 'NodeJs', 'Express'],
            link : 'https://hypherhoops.herokuapp.com/'            
        },
        {
            image : turtleRace,
            title : 'Turtle Race',
            description : 'This is just a mini games made out of javascript. A two player race game. A player will race to the finish line by spamming forward button. Each player has a power ups to pull backwards the opponent with a random distance',
            tools : ['HTML','CSS', 'Javascript'],
            link : 'https://jaspher.gitlab.io/turtlerace/'            
        },
        {
            image : intime,
            title : 'InTime Ecommerce',
            description : 'An ecommerce website for watches with crud functionality. A user must login to be able to purchase.',
            tools : ['Laravel', 'HTML','CSS', 'Javascript', 'jQuery', 'MySQL' ],
            link : 'http://fierce-mountain-88902.herokuapp.com/'            
        }
        
    ]);
    return (
        <div id='projects' className={classes.ProjectContainer}>
            <h1>Projects</h1>
            {Object.keys(projects).map(key => (
                <Aux key={key}>
                    <ProjectCard 
                        image={projects[key].image} 
                        title={projects[key].title} 
                        description={projects[key].description} 
                        tools={projects[key].tools} 
                        link={projects[key].link} />
                </Aux>
            ))}
            
        </div>
    );
}

export default Project;