import React from 'react';
import NavBar from '../NavBar/NavBar';
import classes from './Toolbar.module.scss';
const toolbar = () => (
    <header className={classes.Toolbar}>
        <NavBar/>
    </header>
)

export default toolbar;
