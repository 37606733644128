import React, { Component } from 'react';
// import { Route, Switch, Redirect } from 'react-router-dom';

import Layout from './hoc/Layout/Layout';

class App extends Component {
  render () {
    // const routes = (
    //   <Switch>
    //         <Route path='/about'/>
    //         <Route path='/projects'/>              
    //         <Route path='/contact'/>         
    //         <Redirect to="/"/>
    //   </Switch> 
    // )
    return (
      <div className="App">
          <Layout/>
      </div>
    );
  }
}


export default App;
