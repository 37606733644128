import classes from './Logo.module.scss';
import Aux from '../../hoc/Auxiliary/Auxiliary';

const Logo = () => {
    return (
        <Aux>
            <div className={classes.LogoCont}>
                <p>JsPhr</p>
            </div>
        </Aux>
    )
}

export default Logo;