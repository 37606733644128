import classes from './NavBar.module.scss';
import Aux from '../../../hoc/Auxiliary/Auxiliary';
import NavItem from '../NavItem/NavItem';
import BurgerToggle from '../../BurgerToggle/BurgerToggle';
import SideDrawer from '../../SideDrawer/SideDrawer';
import React, {useEffect , useState} from 'react';
import Logo from '../../Logo/Logo';

const NavigationItems = () => {

    const [navBarClass, setNavBarClass] = useState(classes.NavBar);
    const [burgerToggleStatus, setBurgerToggleStatus] = useState(false);

    useEffect(() => {       
        window.onscroll = () => ( watchScroll());
    })

    const watchScroll = () => {
        const scrollTop = document.documentElement.scrollTop;
        if (scrollTop > 30) {
            const updatedClass = [classes.NavBar, classes.NavBarBackgroundColor].join(' ')
            setNavBarClass(updatedClass)
        } else {
            setNavBarClass(classes.NavBar)
        }
        
    }
    const burgerToggle = () => {       
        setBurgerToggleStatus(!burgerToggleStatus);
    }
    
    return (
        <Aux>
            <nav className={navBarClass}>
                <ul className={classes.DesktopView}>
                    <li className={classes.Logo}><Logo/></li>
                    <NavItem href='#home'>Home</NavItem>
                    <NavItem href='#skills'>Skills</NavItem>
                    <NavItem href='#projects'>Projects</NavItem>
                    <NavItem href='#contact'>Contact</NavItem> 
                </ul>
                <ul className={classes.MobileView}>
                    <li className={classes.Logo}><Logo/></li>
                    <BurgerToggle onClick={burgerToggle}/>                                   
                </ul>
            </nav>
            <SideDrawer open={burgerToggleStatus} onClickBackDrop={burgerToggle}/>
                
        </Aux>
    )
}
    


export default NavigationItems;