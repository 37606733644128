import classes from './Home.module.scss';
import profile from '../../assets/images/profile.jpg';

const home = () => (
    <div id='home' className={classes.HomeContainer}>
        <div className={classes.BackgroundBackLayer}></div>
        <div className={classes.BackgroundLayer}></div>
        <div className={classes.Content}>
            <div className={classes.Profile}>
                <img src={profile} alt='#'/>                                
            </div>            
            <div className={classes.Quotes}>
                <p>"I graduated from Technological University of the Philippines, Manila with a Bachelor of Technology Major in Mechatronics Technology degree.</p>
                <p>I am currently pursuing web development career. I like programming stuff. I made some mini project as listed below just to explore different tools and technologies." <a href='https://drive.google.com/file/d/1aGbHWzvCXbBp3RFvpiKvM8d_IsFOHu6s/view?usp=sharing' target="_blank" rel="noreferrer">Download my CV</a></p>
            </div>
            <div className={classes.Greetings}>
                <p>Hello! I'm Jaspher</p>
                <p>• • •</p>
            </div>
            
        </div>                  
    </div>
)

export default home;


