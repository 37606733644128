import Aux from '../../hoc/Auxiliary/Auxiliary';
import classes from './ProjectCard.module.scss';
const ProjectCard = (props) => {
    return (
       <Aux>
           <a href={props.link} target="_blank" rel="noreferrer" className={classes.Card}>
                <div className={classes.ProjectImg}>
                    <img src={props.image} alt='#'/>
                </div>
                <div className={classes.ProjectDetails}>
                    <h2>{props.title}</h2>
                    <p>{props.description}</p>
                </div>                
                <div className={classes.Tools}>
                    {props.tools.map((tool, index) => (
                        <p key={index}>{tool}</p>
                    ))}                    
                </div>
            </a>
       </Aux>
    );
}

export default ProjectCard;